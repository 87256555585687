@import './../../assets/styles/variables';

.carousel-root {
 margin: auto;
}

.thumbs-wrapper {
    display: none;
}

.carousel .control-dots .dot.selected{
    background-color: #007bff;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.9);
}

.carousel .control-dots .dot{
    opacity: 1 !important;
    width: 14px !important;
    height: 14px !important;
}

.carousel .control-dots .dot:hover {
    background-color: #007bff;
}

.advertising-spinner {
    padding-top: 5%;
    height: 250px;
    @media(max-width: map-get($grid-breakpoints, "md")){
        padding-top: 18%;
        height: 154px;
    }

    & div {
        margin: auto;
    }
}

.banner {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: auto;
    z-index: 0;
    position: relative;
    @media(max-width: map-get($grid-breakpoints, "md")){

    }
}

.banner img {
    height: 250px;
    object-fit: cover;

    @media(max-width: map-get($grid-breakpoints, "md")){
        object-fit: cover;
        height: 154px;
        margin: 0;
        width: 100%;
    }
}

@media (max-width: 480px) {

    .carousel .control-dots .dot{
        opacity: 1 !important;
        width: 8px !important;
        height: 8px !important;
    }

    .banner a {
        font-size: 12px;
        top: 90px;
        width: 114px;
        height: 26px;
    }
}

.banner a:link, .card-mundial a:visited, .card-mundial a:hover, .card-mundial a:active {
    text-decoration: none;
    color: #001926;
}