@import './../../assets/styles/variables';

.home-center{
  background-color: #fdfdfd;
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  min-width: 825px;
  @media(max-width: map-get($grid-breakpoints, "md")){
    min-width: 0;
    width: 100%;
  }
}