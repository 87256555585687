
.ButtonHeader__wrap{
    display: block;
    width: fit-content;
    z-index: 1000;
}

.ButtonHeader__wrap_drop{
    position: absolute;
    top: 24px;
    width: 180px;

    &--top {
        position: absolute;
        top: 44px;
        width: 180px;
    }
}

.ButtonHeader{
    display: flex;
    height: 50px;
    border-radius: 107.898px;
    align-items: center;
    justify-content: center;
    color: #626467;
    cursor: pointer;
    margin: auto;
    min-width: 50px;
    gap:10px;
}

.ButtonHeader__primary {
    color:white;
    background-color:#626467;
}

.ButtonHeader__drop {
    padding: 0px 5px 0px 5px;
}

// DropDown Options
.ButtonHeader__dropDown {
    display: none;
    position: relative;
    background-color: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 19px 19px;
    width: 100%;
    top: -24px;
    left: 0px;
    z-index: -1;
    padding-top: 15px;

}

.ButtonHeader__drop .ButtonHeader__chevron{
    display: flex;
    transform:rotate(0deg);
    transition:0.3s;
}

.ButtonHeader__drop .ButtonHeader__chevron_down{
    display: flex;
    transform:rotate(180deg);
    transition:0.3s;
}

.ButtonHeader__dropDown_open {
    display: block;
}


.ButtonHeader__dropDown ul {
    list-style: none;
    padding-left: 2em;
    text-indent: -1em;
    color: #626467;
    text-indent: -1em;
    margin-top: 10px;
}

.ButtonHeader__dropDown ul li {
    padding: 15px 1em 15px 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

.ButtonHeader__dropDown ul li a{
    font-family: 'Nunito Sans';
    display: flex;
    gap: 21px;
    margin-top: auto;
    margin-bottom: auto;
    color: inherit;
}
