@import './../../../assets/styles/variables';

@-webkit-keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes slideInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  20% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  30% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  60% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  70% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  80% {
    -webkit-transform: translateX(0);
    transform: translateX(0%);
  }

  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    animation-delay: 5000s;
  }
}

body {
  overflow-x: hidden;
}

.button-dita {
  position: absolute;
  width: 195px;
  height: 80px;
  bottom: 0;
  right: 10px;
  max-width: 187px;
  z-index: 9;

  @media(max-width: map-get($grid-breakpoints, "md")){
    bottom: -20%;
  }

  &__img {
    position: absolute;
    bottom: 50%;
    right: 10px;
    @media(max-width: map-get($grid-breakpoints, "md")){
      bottom: 0;
      right: 10px;
    }
  }

  &__content {
    width: 175px;
    height: 41px;
    right: 32%;
    bottom: 56%;
    position: absolute;
    z-index: -9;
    background: #54B2C8;
    border-radius: 7px;
    padding-left: 10px;
    padding-top: 10px;
    box-shadow: 0px 4.7205px 4.7205px rgba(0, 0, 0, 0.25);
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    @media(max-width: map-get($grid-breakpoints, "md")){
      position: absolute;
      bottom: 8%;
      right: 35%;
    }
  }

  &__hide {
    position: absolute;
    right: -5%;
    top: -12%;
    height: 55px;
    width: 40%;
    background-color: $gray-300;
    z-index: -1;

    @media(max-width: map-get($grid-breakpoints, "md")){
      background-color: #FAFAFA;
      width: 40%;
      top: 33%;
    }
  }

  &__label {
    font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #FAFAFA;
  padding-top: 16px;
  padding-bottom: 16px;
  }
}