.menu-user-button{
    width: 156px;
    height: 39px;

    background: #FAFAFA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 9.38889px;

    margin-bottom: 1%;

    flex: none;
    order: 0;
    flex-grow: 0;
    position: relative;
}


a.user-acount-link{
    position: absolute;
    top: 8px;
    margin-left: 4%;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    width: 100%;
    color: #626467;
    align-items: center;
    cursor: pointer;
    padding-left: 2%;
}

a.user-acount-link > img {
    margin-right: 12%;
}

a > img.user-acount-link path {
    color: #676362!important;
}