.Backdrop{
  &.backdrop{
    background-color: rgba(0,0,0, .85);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 160 !important;  
  }
}