@import './../../../assets/styles/variables';

.DefaultLayout{
  display: flex;
  background-color: #F6F6F6;
  min-height: 100vh;
  // padding-left: $side-menu-width;

  @media(max-width: map-get($grid-breakpoints, "md")){
    padding-top: $topbar-height;
  }

  @media(max-width: map-get($grid-breakpoints, "xl")){
    padding-left: unset;
  }

  .content-wrapper{
    padding: 0;
    margin-top:110px;
  }

  .main-section{
    flex: 1;
  }
}