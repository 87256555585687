div.home-button {
    height: 61.83px;
    top: 0px;
    border: none;
    background: #FFFFFF;
    box-shadow: 0px 4.12195px 4.12195px rgba(0, 0, 0, 0.25);
    border-radius: 10.3049px;
    position: relative;
    
    margin-bottom: 2%;
}

.home-div-line{
    position: absolute;
    width: 100%;
    top: 41px;
    height: 4.12px;
    display: block;
}

.home-div-line hr {
    height: 100%;
    margin-left: 22px;
    margin-right: 22px;
    border-radius: 4.12195px 4.12195px 0px 0px;
}

a.home-link {
    position: absolute;
    width: 100%;
    top: 20px;
    outline: none;
    text-decoration: none;
    color: #5F5F5F;
    text-align: center;
    width: 100%;
    cursor: pointer;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

.button-img{
    cursor: pointer;
    width:400px;
    height:187px;
}