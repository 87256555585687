.footer {
    border-radius: 0 !important;
    padding: 0 !important;
    width: 100%;
    height: 441px;
    left: 0px;
    background: #0357AF;
}

.div-footer {
    padding-top: 88px;
    padding-left: 100px;
    padding-right: 100px;
    background: #0357AF;
}

.row-separator-footer {
    padding-top: 100px;
    padding-bottom: 36px;
}

.separator-footer {
    border: 1px solid #FAFAFA;
    background-color: #FAFAFA;
}

.title-footer {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: #FAFAFA;
    display: flex;
    
    padding-bottom: 16px;
}

.min-weight {
    padding-right: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
}

.text-footer {
    width: 359px;
    height: 114px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FAFAFA;
}

.copyright-footer {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #FAFAFA;
}

.btn-footer {
    padding: 10px 24px;
    gap: 10px;
    width: 90px;
    height: 39px;
    left: 1240px;
    top: 376px;
    background: #FFFFFF;
    border-radius: 22px;
    border: 1px solid #FAFAFA;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #0357AF;
}

.div-btn-footer {
    padding-right: 10px;
}

.div-logo-footer {
    padding-left: 22px;
}

.div-social-footer {
    padding-top: 27px;
}

.icon-social-footer {
    padding-right: 35px;
    padding-bottom: 10px;
}

.div-links-footer {
    padding-top: 27px;
}

.link-info-footer{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #FAFAFA;
    
    flex: none;
    order: 1;
    flex-grow: 0;
    /* padding-right: 40px; */
}

.link-info-footer:hover{
    text-decoration: underline;
    color: #FAFAFA;
}