@import './../../assets/styles/variables';

$side-menu-width: 250px !default;
$side-menu-breakpoint: 790px !default;


.OnboardingMenu{
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100vh;
  background-color: $primary;
  box-shadow: 3px 0 8px -1px rgba(0,0,0,0.33);
  width: 0;
  z-index: 200;
  overflow-y: auto;
  max-width: 80%;
  overflow-x: hidden;
  font-size: 1rem;
  font-weight: 400;

  position:absolute;
  right: -$side-menu-width;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: darkgrey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }


  @media(max-width: $side-menu-breakpoint){
    position: fixed;
    flex-basis: 0;
    width: $side-menu-width;
    transition: right .5s ease;
    top: 0;
    bottom: 0;
    height: 100%;
  }

  @media(max-width: $side-menu-breakpoint){
    &.OnboardingMenu--open {
      width: $side-menu-width;
      right: 0;
    }

    &.OnboardingMenu--open + .OnboardingMenu__backdrop{
      display: unset;
    }
  }

  // ----------------------------------------------------------------
  .OnboardingMenu__top{
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    z-index: 99999;
  }

  .OnboardingMenu__top-container{
    height: 80px;
    color: black;
    background: #E9E5E5;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: end;
    padding-bottom: 18px;
  }

  .OnboardingMenu__top-container span{
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #324048;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .OnboardingMenu__logo-container{
    text-align: center;
    width: 100%;
    margin-top: -1px;
  }

  .OnboardingMenu__logo{
    width: 100%;
    margin: 0 0 15px 0;
  }

  .OnboardingMenu__top-divider{
    width: 100%;
    background: #ffffff80;
    height: 2px;
    margin: 10px auto;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
  }

  .OnboardingMenu__user-container{
    text-align: center;
    padding: 0 10px;
    width: 100%;
    padding: 0 10px;
    display: block;
    position: absolute;
    top: 192px;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #FFF;
  }

  .OnboardingMenu__navbar-container{
    display: flex;
    width: 100%;
    flex: 1;
    background-color: white;
    color: $theme-dark-text;
  }

  .OnboardingMenu__backdrop{
    display: none;
  }

  .Navbar{
    flex: 1;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: space-around;
    margin: 0;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-size: 16px;
    color: #324048;
    .Navbar__filler{
      flex: 1;
    }

    .Navbar__buttons-container{
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      align-items: first baseline;
      padding-left: 21px;
      margin-bottom: 20px;
    }
  }

  .NavbarItem{
    
    &:hover{
      //background-color: $primary;

      &> a{
        text-decoration: none;
        //color: $white;
      }
    }

    a { //side-menu__navbar-item_link?
      display: block;
      color: #324048;
      line-height: 1.75em;
      padding: 15px 8px 7px 20px;
    }

    .NavbarItem__icon{
      font-size: 1.25em;
      margin-right: .5em;
      width: 1em;
      display: inline-block;
    }

    .NavbarItem__badge-container{
      margin-left: 5px;
    }

    &.NavbarItem--highlight{
      a{
        color: $primary;
      }

      .NavbarItem__icon{
        background-color: $primary;
        color: white;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 1.3em;
      }
    }
  }

  .NavbarGroup .NavbarItem{
    padding-left: .25em;
  }

  .NavbarGroup{
    position: relative;

    .NavbarGroup__list{
      flex: 1;
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      justify-content: space-around;
      margin: 0;
    }

    .NavbarGroup__list-item{
      padding-left: .25em;
    }

    .NavbarGroup__collapse-ico{
      position: absolute;
      right: 50px;
      top: 15px;
    }
  }

  .NavbarDivider{
    margin: 7px;
    border-bottom:1px solid #E9E9E9;
  }

  .NavbarItem__logout{
    background: #FFF;
  }

  .LineMenu{
    margin: unset;
    width: 258px;
    padding-left: -15px;
    border-color:#E9E9E9;
    margin-top: 10px;
  }

  .pb-15{
    padding-bottom: 15px;
  }

  .maxLetter{
    font-size: 20px !important;
  }
}

