@import './../../assets/styles/variables';

.row-button{
  display: grid;
  grid-template-columns:auto auto;
  gap: 5px;
  margin-bottom: 2%;
}

a:hover {
  color: #12658f;
  text-decoration: none!important;
}

.column-button {
  grid-column: 1/2;
  column-gap: 2px;
}

.column-button-2 {
  grid-column: 2/2;
  column-gap: 2px;
}

.column-button-all {
  grid-column: 1/span 2;
}

.button {
  width: 395px;
  height: 187px;
  border-radius: 10.6211px;
  text-decoration: none;
  @media(max-width: map-get($grid-breakpoints, "md")){
    width: 95%;
    height: 138px;
  }

  &-left {
    margin-right: 5px;
    background: linear-gradient(270deg, #0177B8 0%, #36B6FB 100%);
    box-shadow: 0px 4.7205px 4.7205px rgba(0, 0, 0, 0.25);
    @media(max-width: map-get($grid-breakpoints, "md")){
      margin-right: 10px;
    }
  }

  &-right {
    margin-left: 5px;
    background: linear-gradient(270deg, #3F9BFF -27.95%, #083674 100%);
    box-shadow: 0px 4.69565px 4.69565px rgba(0, 0, 0, 0.25);
    @media(max-width: map-get($grid-breakpoints, "md")){
      margin-left: 10px;
    }
  }

  &__img {
    opacity: 0.4;

    &-left {
      margin-top: 5.9%;
      @media(max-width: map-get($grid-breakpoints, "md")){
        margin-top: 0;
        width: 110px;
      }
    }

    &-right {
      margin-top: 4.2%;
      @media(max-width: map-get($grid-breakpoints, "md")){
        margin-top: 0;
        width: 112px;
      }
    }
  }

  &__text {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-decoration: none;
    color: #FAFAFA;
    float: right;
    padding-top: 20%;
    padding-right: 8%;
    @media(max-width: map-get($grid-breakpoints, "md")){
      float: none;
      font-size: 16px;
      line-height: 22px;
      padding-top: 6px;
      padding-right: 0;
      text-align: center;
      text-decoration: none;
    }
  }
}