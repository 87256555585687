@import './../../assets/styles/variables';

$text-color-default: #FAFAFA;
$box-shadow-default: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

.header-nav-root{
    position: fixed;
    display: flex;
    width: 100%;
    height: 140px;
    font-family: 'Nunito Sans';
    font-style: normal;
    background-color: #EDEDED;
    top: 0;
    box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.25);
    z-index: 150;
}

.header-nav-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.header-nav-content-item__main{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: nowrap;
    justify-content: space-between;

}

.header-nav-content-item__main .header-nav-item {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.header-nav-notification{
    display:flex;
    flex-direction: column;
    margin-left: 15px;
}


.header-nav-logo{
    align-items: center;

}

.header-nav-hamburger{
    float: right;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;

}

.header-nav-hamburger div{
    margin-right: 3px;
}

.header-nav-content-item__options{
    width: 100%;
    height:61px;
    background-color:#FAFAFA;
    display:flex;
    align-items: center;
    justify-content: space-around;
}

.header-nav-content-nav{
    position: absolute;
    bottom: 0;
    height: 60px;
    line-height: 60px;
    width: 100%;
    border: 2px solid #2222;
    background-color: rgba(0, 0, 0, 0.781);
}


.header-nav-notification-right{
    display:flex;
    flex-direction: column;
    align-items: end;
    margin-right: 96px;
    // width: 165.38px;
}


/* Nav Option  */
.nav-option{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 76px;
    height: 37px;

    &-icon {
        font-size: 23px;
        font-weight: bold;
        padding-right: 10px;

        &__chevron {
            padding-right: 0;
            margin-left: 5px;
        }
    }

    &-text {
        font-size: 16px;
    }

    & span {
        font-family: 'Nunito Sans';
        font-style: normal;
        line-height: 25px;
        display: flex;
        background-color: transparent;
        white-space: pre-wrap;
        padding-bottom: 5px;
        padding-top: 5px;
    }
    &__Home:hover {
        color: $text-color-default !important;
        background-color: #626467;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }

    &__Mi-Billetera:hover {
        color: $text-color-default !important;
        background-color: #5000D1;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }

    &__Mis-Vehículos:hover {
        color: $text-color-default !important;
        background-color: #1758A5;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }

    &__Tienda-Virtual:hover {
        color: $text-color-default !important;
        background-color: #00B5E2;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }

    &__Promo-Pre-Mundial:hover {
        color: $text-color-default !important;
        background-color: #2294D2;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }

    &__Prendarios:hover{
        color: $text-color-default !important;
        background-color: #1D93C1;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }

    &-open__Prendarios {
        color: $text-color-default !important;
        background-color: #1D93C1;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }

    &-open__Mis-Seguros{
        color: $text-color-default !important;
        background-color: #E37222;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }

    &__Mis-Seguros:hover{
        color: $text-color-default !important;
        background-color: #E37222;
        box-shadow: $box-shadow-default;
        text-decoration: none;
    }
}

.nav-option-open{
    background: #1D93C1;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.nav-option-open span {
    color: #FAFAFA;
    display: flex;
}

.nav-option-dropdown{
    display: none;
    position: absolute;
    top: 140px;
    background-color: #FAFAFA;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 19px 19px;
    margin-left: -5px;
}

.nav-option-dropdown-open {
    display: block;
}

.nav-option-dropdown ul li::marker {
    content: '\25B6';
    color: #B6B6B6;
}

.nav-option-dropdown ul {
    list-style: none;
    color: #1D93C1;
    text-indent: -1em;
    padding-left: 40px;
}
.nav-option-dropdown ul li {
    text-indent: -1em;
    padding: 15px 1em 15px 0em;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}


.nav-option-dropdown ul li span{
    margin-left: 0.5em;
    font-family: 'Nunito Sans';
    display: block;
}
.nav-option-dropdown ul li:before {

}


.header-buttons-wrap{
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    width: 90%;
    height: 100%;
}


.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

@media(max-width: $side-menu-breakpoint){

    .header-nav-root {
        height: 80px;
    }

    .show-in-desktop {
        display: none;
    }

    .header-nav-content-item__main :nth-child(1){
        align-items: safe;
    }
    .header-nav-content-item__main :nth-child(2){
        align-items: center;
    }
    .header-nav-content-item__main :nth-child(3){
        align-items: end;
    }

}

@media(min-width: $side-menu-breakpoint){

    .show-in-movil {
        display: none;
    }

    .header-nav-content-item__main{
        width: 100%;
        grid-template-columns: repeat(2, 520px);
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .header-nav-content-item__main :nth-child(1){
        align-items: safe;
        flex-direction: row;

    }
    .header-nav-content-item__main :nth-child(2){
        align-items: end;
        flex-direction: row;
        justify-content: end;
    }

}