@import './../../../assets/styles/variables';

.NewLayout{
  display: flex;
  background: linear-gradient(180deg, #E7EAFF 0%, #D8DDFF 100%);
  width: 100%;
  height:auto;
  margin-top: 140px;

  @media(max-width: map-get($grid-breakpoints, "md")){
    padding-top: $topbar-height;
    background: #fdfdfd;
    margin-top: 11px;
  }

  @media (max-width: 1200px) {
    margin-top: 80px;
  }

  @media (max-width: 768px) {
    margin-top: 11px;
  }

  .content-wrapper{
    position: relative;
    padding: 0;
  }

  .main-section{
    flex: 1;
  }
}